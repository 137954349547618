
const je = require('json-encrypt')
let user_permissions = window.localStorage.getItem("user_permissions");
user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));

var modules = [{
  _name: 'CSidebarNavItem',
  name: 'Inicio',
  to: '/inicio/inicio',
  icon: 'cilHome',
}];


if(user_permissions.indexOf('BoxList') > - 1 || user_permissions.indexOf('OtherList') > - 1  || user_permissions.indexOf('ExtraordinaryIncomeList') > - 1 || user_permissions.indexOf('ExtraordinaryExpenseList') > - 1 ){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('BoxList') > -1) {
    item.push({ name: 'Listar', to: '/caja/listar' });
  }
  if (user_permissions.indexOf('BoxAdd') > -1) {
    item.push({ name: 'Caja', to: '/caja/nuevo' });
  }
  if (user_permissions.indexOf('ExtraordinaryExpenseList') > -1) {
    item.push({ name: 'Egresos', to: '/egreso-extraordinario/listar' });
  }
  if (user_permissions.indexOf('OtherList') > -1) {
    item.push({ name: 'Otros', to: '/otros/listar' });
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Caja',
    icon: 'cilCash',
    items: item
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('BusList') > - 1 || user_permissions.indexOf('RouteList') > - 1 || user_permissions.indexOf('TourList') > - 1){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('TourList') > -1) {
    item.push({ name: 'Tours', to: '/tour/listar' });
  }
  if (user_permissions.indexOf('RouteList') > -1) {
    item.push({ name: 'Rutas', to: '/ruta/listar' });
  }
  if (user_permissions.indexOf('BusList') > -1) {
    item.push({ name: 'Unidades', to: '/bus/listar' });
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Tours',
    icon: 'cilPuzzle',
    items: item
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('ClientList') > - 1 || user_permissions.indexOf('CustomerList') > - 1){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('ClientList') > -1) {
    item.push({ name: 'Clientes', to: '/cliente/listar' });
  }
  if (user_permissions.indexOf('CustomerAdd') > -1) {
    item.push({ name: 'Clientes Web', to: '/cliente-web/listar' });
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Clientes',
    icon: 'cilPeople',
    items: item
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('ControlSeatsList') > - 1  ){

  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Control Asientos',
    icon: 'cilCalendar',
    to: '/control-asientos/listar',
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('OrderList') > - 1  ){

  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Pedidos',
    icon: 'cilPuzzle',
    to: '/pedido/listar',
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('SaleList') > - 1  ){

  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Facturación',
    icon: 'cilCalendar',
    to: '/facturacion/listar',
  }
  modules.push(TypeUser);
}




if(user_permissions.indexOf('TestimonialList') > -1 || user_permissions.indexOf('CoverPageList') > -1 
|| user_permissions.indexOf('HotelList') > - 1 || user_permissions.indexOf('PackageList') > - 1 
|| user_permissions.indexOf('PackageCategoryList') > - 1
){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('PackageList') > -1) {
    item.push({ name: 'Paquetes', to: '/paquete/listar' });
  }
  if (user_permissions.indexOf('PackageCategoryList') > -1) {
    item.push({ name: 'Categorias', to: '/categoria-paquetes/listar' });
  }
  if (user_permissions.indexOf('CoverPageList') > -1) {
    item.push({ name: 'Portada', to: '/portada/listar' });
  }
  if (user_permissions.indexOf('HotelList') > -1) {
    item.push({name: 'Hoteles',to: '/hotel/listar'});
  }
  if (user_permissions.indexOf('TestimonialList') > -1) {
    item.push({name: 'Testimonios',to: '/testimonio/listar'});
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Pagina Web',
    icon: 'cilGlobeAlt',
    items: item
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('ReportContributionList') > -1 || user_permissions.indexOf('ReportLoanList') > -1
|| user_permissions.indexOf('ReportLoanPaymentList') > -1 || user_permissions.indexOf('ReportLoanPaymentDetailList') > -1
|| user_permissions.indexOf('ReportContributionDetailList') > -1 || user_permissions.indexOf('ReportMovementList') > -1  
|| user_permissions.indexOf('ReportPaymentStatusList') > -1

){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('ReportLoanList') > -1) {
    item.push({ name: 'Prestamo', to: '/reporte/prestamos' });
  }
  if (user_permissions.indexOf('ReportLoanPaymentList') > -1) {
    item.push({ name: 'Pagos', to: '/reporte/pago-prestamos' });
  }
  if (user_permissions.indexOf('ReportLoanPaymentDetailList') > -1) {
    item.push({ name: 'Pagos Detallado', to: '/reporte/pago-prestamos-detallado' });
  }
  if (user_permissions.indexOf('ReportContributionList') > -1) {
    item.push({ name: 'Aportes', to: '/reporte/aportes' });
  }
  if (user_permissions.indexOf('ReportContributionDetailList') > -1) {
    item.push({ name: 'Aportes Detallado', to: '/reporte/aportes-detallado' });
  }
  if (user_permissions.indexOf('ReportMovementList') > -1) {
    item.push({ name: 'Movimiento', to: '/reporte/movimiento' });
  }
  if (user_permissions.indexOf('ReportPaymentStatusList') > -1) {
    item.push({ name: 'Estado de Pagos', to: '/reporte/estado-pago' });
  }
  
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Reporte',
    icon: 'cilFile',
    items: item
  }
  modules.push(TypeUser);
}



if(user_permissions.indexOf('UserList') > -1 || user_permissions.indexOf('UserTypeList') > -1  || user_permissions.indexOf('VoucherList') > -1 || user_permissions.indexOf('BusinessList') > -1 
){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('UserList') > -1) {
    item.push({ name: 'Usuario', to: '/usuario/listar' });
  }
  if (user_permissions.indexOf('UserTypeList') > -1) {
    item.push({ name: 'Tipo de Usuario', to: '/tipo-usuario/listar'});
  }
  if (user_permissions.indexOf('VoucherList') > -1) {
    item.push({ name: 'Comprobante', to: '/comprobante/listar'});
  }
  if (user_permissions.indexOf('BusinessList') > -1) {
    item.push({name: 'Empresa',to: '/empresa/editar'});
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Mantenimiento',
    icon: 'cilCog',
    items: item
  }
  modules.push(TypeUser);
}

export default [
  {
    _name: 'CSidebarNav',
    _children: modules
  }
]